<template>
    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-container fluid>
            <!-- <v-row no-gutters>
                <v-col cols="10"></v-col>
                <v-col cols="2">
                    <v-btn cols="2" class="success" @click.stop="onDownload">
                        <v-icon>get_app</v-icon>
                        Export
                    </v-btn>
                </v-col>
            </v-row>-->

            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ProvinceID"
                                                        :items="ProvinceList"
                                                        dense
                                                        outlined
                                                        :label="$t('form.select_province')"
                                                        clearable
                                                        @change="getBranchList"
                                                        item-text="value"
                                                        item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.BranchID"
                                                        :items="BranchList"
                                                        :label="$t('form.branch_name')"
                                                        clearable
                                                        dense
                                                        outlined
                                                        item-text="value"
                                                        item-value="id"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <v-btn color="rgb(157, 25, 25)" small dark @click="clearInput">
                                            <i class="material-icons">clear</i>{{$t('clear')}}
                                        </v-btn>
                                        <v-btn @click.stop="onDownload" small class="success float-right mx-2">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            styleClass="vgt-table condensed">
                <template slot="table-column" slot-scope="props">
                    <span>
                        {{ $t(props.column.label) }}
                    </span>
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                </template>
            </vue-good-table>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {},
        computed: {
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('modules_name.hotel'),
                        exact: true,
                        to: '/reportModules/reportHotel'
                    },
                    {
                        text: this.$t('modules_name.monthly_yearly_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        methods: {

            formResponse(data) {
                console.log({ data })
            },

            async onDownload() {
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    ProvinceID: this.serverParams.ProvinceID,
                    DivisionID: this.serverParams.DivisionID,
                    BranchID: this.serverParams.BranchID
                };

                await axios({
                    url: 'Hotel/Download',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportHotelFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.loading = true;

                let param = {

                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    ProvinceID: this.serverParams.ProvinceID,
                    DivisionID: this.serverParams.DivisionID,
                    BranchID: this.serverParams.BranchID
                };
                axios.post("Hotel/GetHotelCustomerInformationListAsync", param).then(response => {
                    this.loading = false;
                    this.rows = response.data.data;
                    this.totalRecords = response.data.totalCount;
                }).catch(err => {
                    console.log(err)
                });
                console.log("load", this.apiData);
            },
            clearInput() {
                this.serverParams.ProvinceID = null
                this.serverParams.DivisionID = null
                this.serverParams.BranchID = null
                this.serverParams.FromDate = null
                this.serverParams.ToDate = null

            },

            async getProvinceList() {
                const ProvinceList = await axios.get('Province/DDLProvinceList')
                this.ProvinceList = ProvinceList.data
                console.log('province list: ', this.ProvinceList)
            },

            async getBranchList(id) {
                const BranchList = await axios.get('Branch/DDLBranchListByProvinceID?ProvinceID=' + id)
                this.BranchList = BranchList.data
                console.log('fjgkdhfgkjhdfgjkdfg', this.BranchList)
            },


            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {

            this.getProvinceList();


        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'full_name',
                        field: 'fullName'
                    },
                    {
                        label: 'passport_number',
                        field: 'passportNumber'
                    },
                    {
                        label: 'check_in_date',
                        field: 'checkInDate'
                    },
                    {
                        label: 'check_out_date',
                        field: 'checkOutDate'
                    },


                ],
                rows: [],
                ProvinceList: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                BranchList: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },

    }
</script>

<style scoped>
</style>